html, body, #app {
  box-sizing: border-box;
  background: #181818;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

:before, :after, * {
  box-sizing: inherit;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlay, .background {
  background: center / cover no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay {
  opacity: .1;
  z-index: 2;
}

.background {
  opacity: .25;
  z-index: 1;
}

.song-name {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa, 0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  font-family: monospace;
  font-size: 16px;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

/*# sourceMappingURL=index.ed43f594.css.map */
