html,
body,
#app {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: #181818;
}

*:before,
*:after,
* {
    box-sizing: inherit;
}

.container {
    width: 100%;
    height: 100%;
    position: relative;
}

.overlay,
.background {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent no-repeat center center;
    background-size: cover;
}

.overlay {
    opacity: 0.1;
    z-index: 2;
}

.background {
    opacity: 0.25;
    z-index: 1;
}

.song-name {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #FFF;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #0fa,
        0 0 82px #0fa,
        0 0 92px #0fa,
        0 0 102px #0fa,
        0 0 151px #0fa;
    font-family: monospace;
    font-size: 16px;
}